import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { decoratedAxiosPost } from "../../req_utils";
import { GENESIS_LOGO_COLOR } from "../../constants";
import { GENESIS_LOGO_BASE64 } from "../../genesis_logo_base64";
import { GENESIS_UTAH_LOGO_BASE64 } from "../../genesis_utah_logo_base64";
import { BUSINESS_BG_BASE64 } from "../../business_bg_base64";
import { COURGETTE_FONT_BASE64 } from "../../courgette_font_base64";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { selectProposalAndRefreshProposals, refreshLogs } from "../../reducers";
import { decoratedFetch } from "../../req_utils";
import { createAutogeneratedLog } from "../../log_utils";
import { Checkbox, Typography } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs["Courgette-Regular.ttf"] = COURGETTE_FONT_BASE64;
pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
  Courgette: {
    normal: "Courgette-Regular.ttf",
    bold: "Courgette-Regular.ttf",
  },
};
const DIVIDER_COLOR = "#D3D3D3";
const HYPERLINK_COLOR = "#2E67D1";
const SECONDARY_COLOR = "#005691";
const COVER_PAGE_X_MARGIN = 40;

const CreateProposalDialog = ({
  open,
  handleClose: handleCloseProp,
  proposal,
  customer = {},
  location = {},
  startDate = null,
  submittedBy = {},
  isProposalDraft,
}) => {
  const dispatchState = useDispatch();
  const project = useSelector((state) => state.project);
  const employee = useSelector((state) => state.employee);
  const proposalDetails = useSelector(state => state.selectedProposalDetails);
  const [proposalName, setProposalName] = useState("");
  const [proposalNameError, setProposalNameError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [materialTotal, setMaterialTotal] = useState(0);
  const [laborTotal, setLaborTotal] = useState(0);
  const [freightTotal, setFreightTotal] = useState(0);
  const [taxableTotal, setTaxableTotal] = useState(0);
  const [addedSalesTax, setAddedSalesTax] = useState(0);
  const [totalAfterSalesTax, setTotalAfterSalesTax] = useState(0);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (proposalDetails?.inclusions?.length) {
      calculateTotals(proposalDetails.inclusions);
    }
  }, [proposalDetails, open]);

  const calculateTotals = (newRows) => {
    let materialTotal = 0;
    let laborTotal = 0;
    let freightTotal = 0;

    if (!proposalDetails.is_itemized) {
      materialTotal = proposal.material_subtotal || 0;
      laborTotal = proposal.labor_subtotal || 0;
      freightTotal = proposal.freight_subtotal || 0;
    } else {
      newRows.forEach((row) => {
        if (!row.extension) {
          return;
        }
        const extension =
          parseFloat(row.extension.replace(/[^0-9.-]+/g, "")) || 0;
        switch (row.category) {
          case "Material":
            materialTotal += extension;
            break;
          case "Labor":
            laborTotal += extension;
            break;
          case "Freight":
            freightTotal += extension;
            break;
          default:
            break;
        }
      });
    }

    if (proposalDetails.include_sales_tax) {
      let salesTaxRate = 0;
      salesTaxRate = parseFloat(proposal.material_sales_tax_rate) / 100;
      const taxableTotal = parseFloat(materialTotal) + parseFloat(freightTotal);
      const addedSalesTax = taxableTotal * salesTaxRate;
      const totalAfterSalesTax =
        parseFloat(materialTotal) +
        parseFloat(freightTotal) +
        parseFloat(laborTotal) +
        addedSalesTax;
      setTaxableTotal(taxableTotal);
      setAddedSalesTax(addedSalesTax);
      setTotalAfterSalesTax(totalAfterSalesTax);
    }

    setMaterialTotal(materialTotal.toFixed(2));
    setLaborTotal(laborTotal.toFixed(2));
    setFreightTotal(freightTotal.toFixed(2));
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    handleCloseProp();
  };

  const getPDFBlob = (docDefinition) => {
    return new Promise((resolve, reject) => {
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBuffer((buffer) => {
        const blob = new Blob([buffer], { type: "application/pdf" });
        resolve(blob);
      });
    });
  };

  const generateUnItemizedTable = (rows) => {
    const descriptions = rows.map((row) => row.description);
    return [
      {
        ol: descriptions.map((inclusion) => inclusion.trim()),
        fontSize: 9,
      },
    ];
  };

  const generateItemizedTable = (rows) => {
    const tableBody = [
      [
        { text: "Quantity", bold: true, fontSize: 8 },
        { text: "Unit", bold: true, fontSize: 8 },
        { text: "Description", bold: true, fontSize: 8 },
        { text: "Unit Price", bold: true, fontSize: 8 },
        { text: "Extension", bold: true, fontSize: 8 },
      ],
      ...rows.map((row) => [
        { text: row.quantity, fontSize: 8 },
        { text: row.unit, fontSize: 8 },
        { text: row.description, fontSize: 8 },
        { text: row.unit_price, fontSize: 8 },
        { text: row.extension, fontSize: 8 },
      ]),
    ];

    return {
      table: {
        headerRows: 1,
        widths: ["10%", "10%", "50%", "15%", "15%"],
        body: tableBody,
      },
      layout: "lightHorizontalLines",
      fillColor: "white",
    };
  };

  const handleSubmit = async () => {
    if (!proposalName) {
      setProposalNameError(true);
      return;
    }

    try {
      setLoading(true);

      const branch_address = project?.genesis_branch_address;
      const is_ca_branch = branch_address?.state === "CA";
      let branch_address_line_1 = "";
      let branch_address_line_2 = "";
      let branch_address_phone_no = "";
      let branch_address_contractor_no = "";
      let branch_address_dir_no = "";
      let ca_wages_clause = "";
      if (branch_address) {
        branch_address_line_1 = `${branch_address.line_1}${branch_address.line_2 ? ` ${branch_address.line_2}` : ""}\n`;
        branch_address_line_2 = `${branch_address.city}, ${branch_address.state} ${branch_address.zip}\n`;
        branch_address_phone_no = `Tel: ${branch_address.phone_no}\n`;
        branch_address_contractor_no = `Contractor Lic. #${branch_address.contractor_no}\n`;
        branch_address_dir_no = `DIR #${branch_address.dir_no}\n`;
        if (is_ca_branch) {
          ca_wages_clause = "Labor is based on Prevailing Wages.";
        }
      }
      const gen_floor_details = [
        is_ca_branch
          ? "Genesis Floor Covering, Inc\n"
          : "Genesis Floor Covering Utah, LLC\n",
        branch_address_line_1,
        branch_address_line_2,
        branch_address_phone_no,
        branch_address_contractor_no,
        branch_address_dir_no,
      ]
      if (proposalDetails?.cmas_id) {
        gen_floor_details.push(proposalDetails?.cmas_id);
      }

      const customer_address = project?.account?.address;
      let customer_name = "";
      let company_name = "";
      let company_address_line_1 = "";
      let company_address_line_2 = "";
      let customer_phone_no = "";
      if (customer_address) {
        customer_name = `${customer.first_name} ${customer.last_name}`;
        company_name = project.account.name;
        company_address_line_1 = `${customer_address.line_1}${customer_address.line_2 ? ` ${customer_address.line_2}` : ""}`;
        company_address_line_2 = `${customer_address.city}${`, ${customer_address.state}${` ${customer_address.zip}`}`}`;
        customer_phone_no = customer.cell_no;
      }

      let projectStartDate = "";
      let projectStartYear = "";
      if (startDate) {
        const startDateObj = new Date(startDate);
        projectStartYear = startDateObj.getFullYear();
        projectStartDate = startDateObj.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }

      const project_name = project?.name ?? "";

      let location_line_1 = "";
      let location_line_2 = "";
      if (location && location.address) {
        location_line_1 = `${location.address?.line_1}${location.address.line_2 ? ` ${location.address.line_2}` : ""}`;
        location_line_2 = `${location.address?.city}${`, ${location.address?.state}${` ${location.address?.zip}`}`}`;
      }

      const p_bond_num = parseFloat(
        proposal.p_and_p_bond ? proposal.p_and_p_bond : "0",
      );

      let total_num = 0;
      let subtotal = "";
      let subtotal_num = 0;
      if (proposalDetails.is_itemized) {
        total_num =
          parseFloat(materialTotal) +
          parseFloat(freightTotal) +
          parseFloat(laborTotal) +
          parseFloat(p_bond_num);
        subtotal_num =
          parseFloat(materialTotal) +
          parseFloat(freightTotal) +
          parseFloat(laborTotal);
        subtotal = subtotal_num >= 0 ? `$${subtotal_num.toFixed(2)}` : "$0.00";
      } else {
        total_num = parseFloat(
          proposal.proposal_total ? proposal.proposal_total : "0",
        );
        subtotal_num = total_num - p_bond_num;
        subtotal = subtotal_num >= 0 ? `$${subtotal_num.toFixed(2)}` : "$0.00";
      }

      if (proposalDetails.include_sales_tax) {
        total_num += parseFloat(addedSalesTax);
      }

      const total = `$${total_num.toFixed(2)}`;
      const p_bond = `$${p_bond_num.toFixed(2)}`;

      const submitted_by_name = `${submittedBy.first_name} ${submittedBy.last_name}`;
      const submitted_by_phone_no = submittedBy.cell_no ?? "";
      const submitted_by_email = submittedBy.email ?? "";

      const salesTaxRate = proposal.material_sales_tax_rate;

      const linesAfterSubtotal = p_bond_num > 0 || proposalDetails.include_sales_tax;
      let totalsBody = [];
      if (proposalDetails.include_price_category_breakdown) {
        totalsBody.push(
          [
            {
              text: "Material",
              fontSize: 11,
            },
            { text: `$${materialTotal}`, fontSize: 11, alignment: "right" },
          ],
          [
            {
              text: "Freight",
              fontSize: 11,
            },
            { text: `$${freightTotal}`, fontSize: 11, alignment: "right" },
          ],
          [
            {
              text: "Labor",
              fontSize: 11,
            },
            { text: `$${laborTotal}`, fontSize: 11, alignment: "right" },
          ],
        );
        if (linesAfterSubtotal) {
          totalsBody.push(
            [
              {
                text: "Subtotal",
                fontSize: 11,
                bold: true,
              },
              { text: subtotal, bold: true, fontSize: 11, alignment: "right" },
            ],
          );
        } else {
          totalsBody.push(
            [
              {
                text: "Total Price",
                fontSize: 11,
                bold: true,
                fillColor: GENESIS_LOGO_COLOR,
                color: "white",
              },
              {
                text: subtotal,
                bold: true,
                fontSize: 11,
                alignment: "right",
                fillColor: GENESIS_LOGO_COLOR,
                color: "white",
              },
            ],
          );
        }
      } else {
        let subtotalLine;
        if (proposalDetails?.cmas_id?.length) {
          subtotalLine = linesAfterSubtotal ? "Subtotal" : "Total Price";
        } else {
          subtotalLine = proposalDetails.include_sales_tax
            ? "Total Materials, Freight, and Installation Labor"
            : "Total Materials, Freight, Sales Tax, and Installation Labor";
        }
        totalsBody.push([
          {
            text: subtotalLine,
            fontSize: 11,
            bold: true,
            fillColor: linesAfterSubtotal ? "white" : GENESIS_LOGO_COLOR,
            color: linesAfterSubtotal ? "black" : "white",
          },
          {
            text: subtotal,
            fontSize: 11,
            alignment: "right",
            bold: true,
            fillColor: linesAfterSubtotal ? "white" : GENESIS_LOGO_COLOR,
            color: linesAfterSubtotal ? "black" : "white",
          },
        ]);
      }
      if (proposalDetails.include_sales_tax) {
        totalsBody.push(
          [
            {
              text: "Taxable Subtotal",
              fontSize: 11,
            },
            {
              text: `$${taxableTotal.toFixed(2)}`,
              fontSize: 11,
              alignment: "right",
            },
          ],
          [
            {
              text: "Sales Tax Rate",
              fontSize: 11,
            },
            {
              text: `${salesTaxRate.toFixed(2)}%`,
              fontSize: 11,
              alignment: "right",
            },
          ],
          [
            {
              text: "Sales Tax",
              fontSize: 11,
            },
            {
              text: `$${addedSalesTax.toFixed(2)}`,
              fontSize: 11,
              alignment: "right",
            },
          ],
          [
            {
              text: "Total Price",
              fontSize: 11,
              bold: true,
              fillColor: p_bond_num > 0 ? "white" : GENESIS_LOGO_COLOR,
              color: p_bond_num > 0 ? "black" : "white",
            },
            {
              text: `$${totalAfterSalesTax.toFixed(2)}`,
              fontSize: 11,
              bold: true,
              fillColor: p_bond_num > 0 ? "white" : GENESIS_LOGO_COLOR,
              color: p_bond_num > 0 ? "black" : "white",
              alignment: "right",
            },
          ],
        );
      }
      if (p_bond_num > 0) {
        totalsBody.push(
          [
            {
              text: "Payment & Performance Bond (Not Included in Total)",
              fontSize: 11,
            },
            { text: p_bond, fontSize: 11, alignment: "right" },
          ],
          [
            {
              text: "Total Price Including Payment & Performance Bond",
              fontSize: 11,
              bold: true,
              fillColor: GENESIS_LOGO_COLOR,
              color: "white",
            },
            {
              text: total,
              fontSize: 11,
              bold: true,
              fillColor: GENESIS_LOGO_COLOR,
              color: "white",
              alignment: "right",
            },
          ],
        );
      }

      const docDefinition = {
        content: [
          {
            image: is_ca_branch
              ? GENESIS_LOGO_BASE64
              : GENESIS_UTAH_LOGO_BASE64,
            width: is_ca_branch ? 200 : 250,
            height: is_ca_branch ? 80 : 100,
            alignment: "left",
            margin: [
              is_ca_branch ? COVER_PAGE_X_MARGIN : COVER_PAGE_X_MARGIN - 20,
              10,
              20,
              0,
            ],
          },
          {
            text: project_name,
            fontSize: 45,
            bold: true,
            color: "#003086",
            margin: [COVER_PAGE_X_MARGIN, 70, COVER_PAGE_X_MARGIN + 40, 0],
          },
          {
            text: proposalDetails?.cmas_id,
            fontSize: 20,
            bold: true,
            color: proposalDetails?.cmas_id?.length ? "black" : "white",
            margin: [COVER_PAGE_X_MARGIN, 30, COVER_PAGE_X_MARGIN + 40, 0],
          },
          {
            columns: [
              {
                stack: [
                  {
                    text: is_ca_branch
                      ? "Genesis Floor Covering, Inc\n"
                      : "Genesis Floor Covering Utah, LLC\n",
                    fontSize: 14,
                    bold: "true",
                  },
                  {
                    text: branch_address_line_1,
                    fontSize: 14,
                    margin: [0, 2, 0, 0],
                  },
                  {
                    text: branch_address_line_2,
                    fontSize: 14,
                    margin: [0, 2, 0, 0],
                  },
                  {
                    text: branch_address_contractor_no,
                    fontSize: 14,
                    margin: [0, 2, 0, 0],
                  },
                  {
                    text: branch_address_dir_no,
                    fontSize: 14,
                    margin: [0, 2, 0, 0],
                  },
                  {
                    text: "www.genesisfloor.net",
                    fontSize: 14,
                    link: "https://www.genesisfloor.net",
                    color: HYPERLINK_COLOR,
                    margin: [0, 2, 0, 0],
                  },
                ],
              },
            ],
            absolutePosition: { x: COVER_PAGE_X_MARGIN + 42, y: 610 },
          },
          {
            text: projectStartYear,
            fontSize: 24,
            color: "white",
            bold: true,
            absolutePosition: { x: COVER_PAGE_X_MARGIN + 455, y: 770 },
          },
          {
            text: "",
            pageBreak: "after",
          },
          {
            columns: [
              {
                image: is_ca_branch
                  ? GENESIS_LOGO_BASE64
                  : GENESIS_UTAH_LOGO_BASE64,
                width: 200,
                height: 80,
                alignment: "left",
              },
              {
                text: gen_floor_details,
                style: "headerRight",
                width: "*",
                alignment: "right",
              },
            ],
          },
          {
            columns: [
              {
                canvas: [
                  {
                    type: "line",
                    x1: 0,
                    y1: 0,
                    x2: 208,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: SECONDARY_COLOR,
                  },
                ],
                width: "*",
                margin: [0, 10, 0, 0],
              },
              {
                text: "Proposal",
                style: "headerCenter",
                color: SECONDARY_COLOR,
                width: "auto",
                margin: [10, 0, 5, 5],
              },
              {
                canvas: [
                  {
                    type: "line",
                    x1: 0,
                    y1: 0,
                    x2: 208,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: SECONDARY_COLOR,
                  },
                ],
                width: "*",
                margin: [0, 10, 0, 0],
              },
            ],
            columnGap: 10,
          },
          {
            margin: [0, 0, 0, 5],
            table: {
              widths: ["20%", "30%", "15%", "30%"],
              body: [
                [
                  {
                    text: "Customer:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.72,
                  },
                  { text: customer_name, fontSize: 10, lineHeight: 0.7 },
                  {
                    text: "Date:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.72,
                  },
                  { text: projectStartDate, fontSize: 10, lineHeight: 0.72 },
                ],
                [
                  {
                    text: "Company:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.84,
                  },
                  { text: company_name, fontSize: 10, lineHeight: 0.84 },
                  {
                    text: "Project:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.84,
                  },
                  { text: project_name, fontSize: 10, lineHeight: 0.84 },
                ],
                [
                  {
                    text: "Address:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.7,
                  },
                  {
                    text: company_address_line_1,
                    fontSize: 10,
                    lineHeight: 0.7,
                  },
                  {
                    text: "Location:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    lineHeight: 0.7,
                  },
                  { text: location_line_1, fontSize: 10, lineHeight: 0.7 },
                ],
                [
                  "",
                  {
                    text: company_address_line_2,
                    fontSize: 10,
                    lineHeight: 0.74,
                  },
                  "",
                  { text: location_line_2, fontSize: 10, lineHeight: 0.74 },
                ],
                [
                  {
                    text: "Telephone:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                  },
                  { text: customer_phone_no, fontSize: 10 },
                  "",
                  "",
                ],
              ],
            },
            layout: "noBorders",
          },
          {
            text: [
              {
                text: "We herewith submit proposal for labor and materials to be supplied at the request of above party and at premises set forth above. ",
                bold: true,
                fontSize: 10,
              },
              {
                text: ca_wages_clause,
                italics: true,
                fontSize: 10,
                bold: true,
              },
            ],
            margin: [0, 2, 0, 8],
          },
          {
            text: [
              {
                text: "INCLUDED AREA: ",
                bold: true,
              },
              { text: proposalDetails?.included_area },
            ],
            margin: [0, 0, 0, 8],
            fontSize: 10,
          },
          {
            text: "SCOPE OF WORK:",
            bold: true,
            fontSize: 10,
            margin: [0, 0, 0, 3],
          },
          {
            stack: proposalDetails.is_itemized
              ? [generateItemizedTable(proposalDetails.inclusions)]
              : generateUnItemizedTable(proposalDetails.inclusions),
            margin: [0, 0, 0, 8],
          },
          {
            text: "EXCLUSIONS:",
            bold: true,
            fontSize: 10,
            margin: [0, 0, 0, 3],
          },
          {
            ol: proposalDetails.exclusions.map((exclusion) => exclusion.description.trim()),
            margin: [0, 0, 0, 8],
            fontSize: 9,
          },
          {
            margin: [0, 0, 0, 7],
            table: {
              widths: ["*", "auto"],
              body: totalsBody,
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0.5;
              },
              vLineWidth: function (i, node) {
                return 0.5;
              },
              hLineColor: function (i, node) {
                return SECONDARY_COLOR;
              },
              vLineColor: function (i, node) {
                return SECONDARY_COLOR;
              },
            },
          },
          {
            text: "NOTES:",
            bold: true,
            fontSize: 8,
          },
          {
            ol: proposalDetails.notes.map((note) => note.description.trim()),
            margin: [10, 0, 0, 2],
            fontSize: 8,
          },
          {
            table: {
              widths: ["14%", "*", "14%", "*"],
              body: [
                [
                  {
                    text: "Submitted By:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 20, 0, 30],
                  },
                  {
                    text: submitted_by_name,
                    font: "Courgette",
                    bold: true,
                    fontSize: 14,
                    margin: [0, 16, 0, 30],
                  },
                  {
                    text: "Tel:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 20, 0, 30],
                  },
                  {
                    text: submitted_by_phone_no,
                    fontSize: 10,
                    margin: [0, 20, 0, 30],
                  },
                ],
                [
                  {
                    text: "Accepted By:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                  },
                  { text: "___________________________________", fontSize: 10 },
                  {
                    text: "Date:",
                    alignment: "right",
                    bold: true,
                    fontSize: 10,
                  },
                  { text: "___________________________", fontSize: 10 },
                ],
              ],
            },
            layout: "noBorders",
          },
        ],
        background: (currentPage, pageSize) => {
          if (currentPage === 1) {
            return {
              image: BUSINESS_BG_BASE64,
              width: pageSize.width,
              height: pageSize.height,
              absolutePosition: { x: 0, y: 0 },
            };
          }
        },
        styles: {
          headerRight: {
            alignment: "right",
            fontSize: 9,
            color: SECONDARY_COLOR,
          },
          headerCenter: {
            bold: true,
            fontSize: 18,
            alignment: "center",
            margin: [0, 18, 0, 20],
          },
        },
        defaultStyle: {
          columnGap: 20,
        },
      };

      const pdfBlob = await getPDFBlob(docDefinition);
      const fileName = proposalName + ".pdf";

      const { data } = await decoratedAxiosPost(
        "/get_s3_presigned_urls_for_upload",
        {
          project_id: project.id,
          files: [
            {
              name: fileName,
              type: "application/pdf",
            },
          ],
        },
      );
      const newFile = {
        name: fileName,
        type: "pdf",
        s3_key: data[0].s3_key,
      };

      const res = await decoratedAxiosPost("/create_proposal", {
        project_id: project.id,
        proposal_draft_id: isProposalDraft ? proposal.id : null,
        name: proposalName,
        genesis_branch_address_id: project?.genesis_branch_address?.id,
        file: newFile,
      });

      const result = await axios.put(data[0].presigned_url, pdfBlob, {
        headers: {
          "Content-Type": "application/pdf",
        },
      });

      if (isProposalDraft) {
        dispatchState(selectProposalAndRefreshProposals(res.data));
        createAutogeneratedLog(
          project?.id,
          employee?.id,
          `Proposal \"${proposalName}\" created by ${employee.first_name} ${employee.last_name}`,
        )
          .then((response) => {
            if (response.status !== 201) {
              throw new Error("Error occurred");
            }
            dispatchState(refreshLogs(true));
          })
          .catch((error) => {
            console.error(`Error creating log: ${error}`);
          });
      }

      try {
        const { data } = await decoratedAxiosPost(
          "/get_s3_presigned_url_for_download",
          {
            s3_key: newFile.s3_key,
          },
        );
        setLoading(false);
        handleCloseProp(true, newFile, data.presigned_url);
      } catch (error) {
        console.error("Error previewing file:", error);
        handleCloseProp(true);
      }
    } catch (error) {
      setLoading(false);
      if (error.response?.data?.msg) {
        setAlertMessage(error.response.data.msg);
      } else {
        setAlertMessage("An error occurred when creating proposal");
      }
      console.error("Error creating proposal:", error);
    }
  };

  const onProposalNameChange = (newName) => {
    setProposalNameError(false);
    setProposalName(newName);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      disableEscapeKeyDown
      disableScrollLock
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {isProposalDraft ? "Create Proposal" : "Create Proposal PDF"}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={1}
          sx={{ mt: '5px' }}
        >
          {alertMessage && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Alert
                onClose={() => setAlertMessage("")}
                severity={"error"}
                sx={{
                  width: "80%",
                  borderRadius: 8,
                  marginBottom: "15px",
                }}
              >
                {alertMessage}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label={isProposalDraft ? "Proposal Name" : "File Name"}
              variant="outlined"
              fullWidth
              size="small"
              value={proposalName}
              onChange={(e) => onProposalNameChange(e.target.value)}
              error={proposalNameError}
              helperText={proposalNameError ? "Required" : ""}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pt: "15px" }}>
        {loading ? (
          <CircularProgress
            size="25px"
            sx={{ textAlign: "center", mt: "6px", mb: "5.5px", mr: "30px" }}
          />
        ) : (
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleSubmit}>
              Create
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateProposalDialog;
