import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import { updateSelectedProposalDetails } from "../reducers";
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR } from "../constants";

const ProposalDetails = ({ open }) => {
  const dispatchState = useDispatch();
  const proposal = useSelector((state) => state.selectedProposal);
  const proposalId = useSelector((state) => state.selectedProposal.id);
  const proposalDetails = useSelector((state) => state.selectedProposalDetails);
  const proposalInclusions = useSelector((state) => state.selectedProposalDetails.inclusions);
  const proposalExclusions = useSelector((state) => state.selectedProposalDetails.exclusions);
  const proposalNotes = useSelector((state) => state.selectedProposalDetails.notes);
  const project = useSelector((state) => state.project);
  const employee = useSelector((state) => state.employee);
  const [proposalTemplates, setProposalTemplates] = useState([]);
  const [selectedInclusions, setSelectedInclusions] = useState([]);
  const [selectedExclusions, setSelectedExclusions] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [materialTotal, setMaterialTotal] = useState(0);
  const [laborTotal, setLaborTotal] = useState(0);
  const [freightTotal, setFreightTotal] = useState(0);
  const [taxableTotal, setTaxableTotal] = useState(0);
  const [addedSalesTax, setAddedSalesTax] = useState(0);
  const [totalAfterSalesTax, setTotalAfterSalesTax] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const inclusionsApiRef = useGridApiRef();
  const exclusionsApiRef = useGridApiRef();
  const notesApiRef = useGridApiRef();
  const enterPressedRef = useRef(false);
  const startEditModeOnLastRowRef = useRef(false);

  const updateProposal = fieldsToUpdate => {
    dispatchState(updateSelectedProposalDetails(fieldsToUpdate));
    decoratedFetch(`/update_proposal/${proposal.id}`, {
      method: "PUT",
      body: JSON.stringify(fieldsToUpdate),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Error message generated");
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to save proposal");
        console.error(`Error updating proposal: ${error}`);
      });
  };

  const handleProposalTemplateChange = selectedTemplateId => {
    updateProposal({ 'proposal_template_id': selectedTemplateId });

    decoratedFetch(`/look_up_proposal_template?id=${selectedTemplateId}`)
      .then((response) => response.json())
      .then((data) => {
        const inclusionsWithIds =
          data?.inclusions?.map((description, index) => ({
            id: index,
            description,
          })) || [];
        const exclusionsWithIds =
          data?.exclusions?.map((description, index) => ({
            id: index,
            description,
          })) || [];
        const notesWithIds =
          data?.notes?.map((description, index) => ({
            id: index,
            description,
          })) || [];
        updateProposal({ 'inclusions': inclusionsWithIds, 'exclusions': exclusionsWithIds, 'notes': notesWithIds, 'cmas_id': data?.cmas_id });
      })
      .catch((error) => {
        console.error(`Error listing proposal templates: ${error}`);
      });
  };

  const descriptionColumns = [
    {
      field: "description",
      headerName: "Description",
      editable: true,
      flex: 1,
    },
  ];

  const handleAddNoteRow = () => {
    const newRow = {
      id: proposalDetails.notes.length,
      description: "",
    };
    startEditModeOnLastRowRef.current = true;
    updateProposal({ 'notes': [...proposalDetails.notes, newRow] });
  };

  const handleNoteRowDelete = () => {
    updateProposal({
      'notes': proposalDetails.notes
        .filter((row) => !selectedNotes.includes(row.id))
        .map((row, i) => ({ ...row, id: i }))
    });
    setSelectedNotes([]); // Clear selection after deletion
  };

  const handleNoteRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedNotes(newSelectionModel);
    },
    [setSelectedNotes],
  );

  const handleNoteEditCommit = (updatedRow) => {
    if (!updatedRow.description) {
      updateProposal({
        'notes': proposalDetails.notes.filter(note => note.id !== updatedRow.id).map((note, i) => ({ ...note, id: i })),
      });
      enterPressedRef.current = false;
      return updatedRow;
    }
    const newRows = proposalDetails.notes.map((row) => {
      if (row.id === updatedRow.id) {
        return {
          id: row.id,
          description: updatedRow.description,
        };
      }
      return row;
    });
    if (
      enterPressedRef.current &&
      updatedRow.id === proposalDetails.notes[proposalDetails.notes.length - 1].id
    ) {
      // If the user clicked "Enter" on the last row
      const newRow = {
        id: proposalDetails.notes.length,
        description: "",
      };
      newRows.push(newRow);
      startEditModeOnLastRowRef.current = true;
    }
    enterPressedRef.current = false;
    updateProposal({ 'notes': newRows });
    return updatedRow;
  };

  const handleExclusionEditCommit = (updatedRow) => {
    if (!updatedRow.description) {
      updateProposal({
        'exclusions': proposalDetails.exclusions.filter(excl => excl.id !== updatedRow.id).map((excl, i) => ({ ...excl, id: i })),
      });
      enterPressedRef.current = false;
      return updatedRow;
    }
    const newRows = proposalDetails.exclusions.map((row) => {
      if (row.id === updatedRow.id) {
        return {
          id: row.id,
          description: updatedRow.description,
        };
      }
      return row;
    });
    if (
      enterPressedRef.current &&
      updatedRow.id === proposalDetails.exclusions[proposalDetails.exclusions.length - 1].id
    ) {
      // If the user clicked "Enter" on the last row
      const newRow = {
        id: proposalDetails.exclusions.length,
        description: "",
      };
      newRows.push(newRow);
      startEditModeOnLastRowRef.current = true;
    }
    enterPressedRef.current = false;
    updateProposal({ 'exclusions': newRows });
    return updatedRow;
  };

  const handleAddExclusionRow = () => {
    const newRow = {
      id: proposalDetails.exclusions.length,
      description: "",
    };
    startEditModeOnLastRowRef.current = true;
    updateProposal({ 'exclusions': [...proposalDetails.exclusions, newRow] });
  };

  const handleExclusionRowDelete = () => {
    updateProposal({
      'exclusions': proposalDetails.exclusions
        .filter((row) => !selectedExclusions.includes(row.id))
        .map((row, i) => ({ ...row, id: i }))
    });
    setSelectedExclusions([]); // Clear selection after deletion
  };

  const handleExclusionRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedExclusions(newSelectionModel);
    },
    [setSelectedExclusions],
  );

  const handleInclusionEditCommit = (updatedRow) => {
    if (!updatedRow.description) {
      const newRows = proposalDetails.inclusions.filter(incl => incl.id !== updatedRow.id).map((incl, i) => ({ ...incl, id: i }));
      updateProposal({
        'inclusions': newRows,
      });
      enterPressedRef.current = false;
      calculateTotals(newRows);
      return updatedRow;
    }
    const newRows = proposalDetails.inclusions.map((row) => {
      if (row.id === updatedRow.id) {
        const quantity = parseFloat(updatedRow.quantity) || 0;
        const unitPriceString = updatedRow.unit_price
          ? updatedRow.unit_price.toString()
          : "0";
        const unitPrice =
          parseFloat(unitPriceString.replace(/[^0-9.-]+/g, "")).toFixed(2) || 0;
        return {
          ...row,
          ...updatedRow,
          unit_price: `$${unitPrice}`,
          extension: `$${(quantity * unitPrice).toFixed(2)}`,
        };
      }
      return row;
    });
    if (
      enterPressedRef.current &&
      updatedRow.id === proposalDetails.inclusions[proposalDetails.inclusions.length - 1].id
    ) {
      // If the user clicked "Enter" on the last row
      const newRow = {
        id: proposalDetails.inclusions.length,
        quantity: "",
        unit: "",
        description: "",
        unit_price: "",
        extension: "",
        category: "",
      };
      newRows.push(newRow);
      startEditModeOnLastRowRef.current = true;
    }
    enterPressedRef.current = false;
    updateProposal({ 'inclusions': newRows });
    calculateTotals(newRows);
    return updatedRow;
  };

  // This effect is just to focus on the last row's description column when enter was recently pressed on the last row
  useEffect(() => {
    if (startEditModeOnLastRowRef.current && proposalInclusions.length && inclusionsApiRef?.current && Object.keys(inclusionsApiRef.current).length !== 0) {
      inclusionsApiRef.current.setCellFocus(
        proposalInclusions.length - 1,
        "description",
      );
      inclusionsApiRef.current.startCellEditMode({
        id: proposalInclusions.length - 1,
        field: "description",
      });
      startEditModeOnLastRowRef.current = false;
    }
  }, [inclusionsApiRef, proposalInclusions]);

  // This effect is just to focus on the last row's description column when enter was recently pressed on the last row
  useEffect(() => {
    if (startEditModeOnLastRowRef.current && proposalExclusions.length && exclusionsApiRef?.current && Object.keys(exclusionsApiRef.current).length !== 0) {
      exclusionsApiRef.current.setCellFocus(
        proposalExclusions.length - 1,
        "description",
      );
      exclusionsApiRef.current.startCellEditMode({
        id: proposalExclusions.length - 1,
        field: "description",
      });
      startEditModeOnLastRowRef.current = false;
    }
  }, [exclusionsApiRef, proposalExclusions]);

  // This effect is just to focus on the last row's description column when enter was recently pressed on the last row
  useEffect(() => {
    if (startEditModeOnLastRowRef.current && proposalNotes.length && notesApiRef?.current && Object.keys(notesApiRef.current).length !== 0) {
      notesApiRef.current.setCellFocus(
        proposalNotes.length - 1,
        "description",
      );
      notesApiRef.current.startCellEditMode({
        id: proposalNotes.length - 1,
        field: "description",
      });
      startEditModeOnLastRowRef.current = false;
    }
  }, [notesApiRef, proposalNotes]);

  useEffect(() => {
    if (!open) {
      return;
    }
    if (proposalInclusions?.length) {
      calculateTotals(proposalInclusions);
    }
  }, [proposalInclusions, open]);

  const handleAddInclusionRow = () => {
    const newRow = {
      id: proposalDetails.inclusions.length,
      quantity: "",
      unit: "",
      description: "",
      unit_price: "",
      extension: "",
      category: "",
    };
    startEditModeOnLastRowRef.current = true;
    updateProposal({ 'inclusions': [...proposalDetails.inclusions, newRow] });
  };

  const handleInclusionRowDelete = () => {
    updateProposal({
      'inclusions': proposalDetails.inclusions
        .filter((row) => !selectedInclusions.includes(row.id))
        .map((row, i) => ({ ...row, id: i }))
    });
    setSelectedInclusions([]); // Clear selection after deletion
  };

  const itemizedColumns = [
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      editable: true,
      flex: 1,
    },
    { field: "unit",
      headerName: "Unit",
      editable: true,
      flex: 0.5,
    },
    {
      field: "category",
      headerName: "Category",
      editable: true,
      width: 175,
      type: "singleSelect",
      valueOptions: ["Material", "Freight", "Labor"],
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      width: 380,
      flex: 3,
    },
    {
      field: "unit_price",
      headerName: "Unit Price",
      type: "number",
      editable: true,
      flex: 1,
    },
    {
      field: "extension",
      headerName: "Extension",
      type: "number",
      editable: false,
      flex: 1,
    },
  ];

  const handleInclusionRowSelectionModelChange = useCallback(
    (newSelectionModel) => {
      setSelectedInclusions(newSelectionModel);
    },
    [setSelectedInclusions],
  );

  const calculateTotals = (newRows) => {
    let materialTotal = 0;
    let laborTotal = 0;
    let freightTotal = 0;

    newRows.forEach((row) => {
      if (!row.extension) {
        return;
      }
      const extension =
        parseFloat(row.extension.replace(/[^0-9.-]+/g, "")) || 0;
      switch (row.category) {
        case "Material":
          materialTotal += extension;
          break;
        case "Labor":
          laborTotal += extension;
          break;
        case "Freight":
          freightTotal += extension;
          break;
        default:
          break;
      }
    });

    if (proposalDetails.include_sales_tax) {
      let salesTaxRate = 0;
      salesTaxRate = parseFloat(proposal.material_sales_tax_rate) / 100;
      const taxableTotal = parseFloat(materialTotal) + parseFloat(freightTotal);
      const addedSalesTax = taxableTotal * salesTaxRate;
      const totalAfterSalesTax =
        parseFloat(materialTotal) +
        parseFloat(freightTotal) +
        parseFloat(laborTotal) +
        addedSalesTax;
      setTaxableTotal(taxableTotal);
      setAddedSalesTax(addedSalesTax);
      setTotalAfterSalesTax(totalAfterSalesTax);
    }

    setMaterialTotal(materialTotal.toFixed(2));
    setLaborTotal(laborTotal.toFixed(2));
    setFreightTotal(freightTotal.toFixed(2));
  };

  const listProposalTemplates = () => {
    decoratedFetch("/list_proposal_templates")
      .then((response) => response.json())
      .then((data) => {
        setProposalTemplates(data);
      })
      .catch((error) => {
        console.error(`Error listing proposal templates: ${error}`);
      });
  };

  const getProposalDetails = () => {
    decoratedFetch(`/look_up_proposal_details?proposal_id=${proposal?.id}`)
      .then((response) => response.json())
      .then((data) => {
        const inclusionsWithIds =
          data?.inclusions?.map((inclusion, index) => ({
            id: index,
            ...inclusion,
          })) || [];
        const exclusionsWithIds =
          data?.exclusions?.map((description, index) => ({
            id: index,
            description,
          })) || [];
        const notesWithIds =
          data?.notes?.map((description, index) => ({
            id: index,
            description,
          })) || [];
        dispatchState(updateSelectedProposalDetails({ ...data, 'inclusions': inclusionsWithIds, 'exclusions': exclusionsWithIds, 'notes': notesWithIds }));
      })
      .catch((error) => {
        console.error(`Error looking up proposal details: ${error}`);
      });
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    if (proposal && Object.keys(proposal).length !== 0) {
      getProposalDetails();
    }
    listProposalTemplates();
  }, [open, proposalId]);

  const importMaterialItems = () => {
    decoratedFetch(
      `/list_project_material_items?proposal_id=${proposal.id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const newRows = data.map((item, index) => ({
            id: proposalDetails.inclusions.length + index,
            quantity: item?.quantity,
            unit: item.size_unit?.name,
            unit_price: `$${item?.unit_price}`,
            category: "Material",
            description: `Furnish ${item.material_vendor} ${item.material_type} ${item.material_style} ${item.material_color}`,
            extension: `$${(item?.quantity * parseFloat(item?.unit_price)).toFixed(2)}`,
          }));
          updateProposal({ 'inclusions': [...proposalDetails.inclusions, ...newRows] });
        }
      })
      .catch((error) => {
        console.error(`Error listing materials: ${error}`);
      });
  };

  const importFreightItems = () => {
    decoratedFetch(
      `/list_project_freight_items?proposal_id=${proposal.id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const newRows = data.map((item, index) => ({
            id: proposalDetails.inclusions.length + index,
            quantity: item?.quantity,
            unit: item.size_unit?.name,
            unit_price: `$${item?.unit_price}`,
            category: "Freight",
            description: `${item.freight_item_type} ${item.description}`,
            extension: `$${(item?.quantity * parseFloat(item?.unit_price)).toFixed(2)}`,
          }));
          updateProposal({ 'inclusions': [...proposalDetails.inclusions, ...newRows] });
        }
      })
      .catch((error) => {
        console.error(`Error listing freight: ${error}`);
      });
  };

  const importLaborItems = () => {
    decoratedFetch(
      `/list_project_demo_items?proposal_id=${proposal.id}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          const newRows = data.map((item, index) => ({
            id: proposalDetails.inclusions.length + index,
            quantity: item?.quantity,
            unit: item.size_unit?.name,
            unit_price: `$${item?.unit_price}`,
            category: "Labor",
            description: `${item.labor_item_type.name} ${item.demo_item_type}`,
            extension: `$${(item?.quantity * parseFloat(item?.unit_price)).toFixed(2)}`,
          }));
          updateProposal({ 'inclusions': [...proposalDetails.inclusions, ...newRows] });
        }
      })
      .catch((error) => {
        console.error(`Error listing labor: ${error}`);
      });
  };

  const handleCellKeyDown = (params, event) => {
    if (event.key === "Enter") {
      enterPressedRef.current = true;
      return;
    }
    enterPressedRef.current = false;
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{
        maxHeight: "620px",
        overflowY: "auto",
        backgroundColor: "white",
      }}
    >
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
      />
      <Grid item xs={12} sm={6} sx={{ mb: "10px" }}>
        <FormControl
          variant="outlined"
          sx={{
            minWidth: 120,
            width: "100%",
          }}
          size="small"
        >
          <InputLabel>Template</InputLabel>
          <Select
            id="template-select"
            value={proposalDetails?.proposal_template_id ? proposalDetails?.proposal_template_id : ""}
            onChange={e => handleProposalTemplateChange(e.target.value)}
            label="Template"
            fullWidth
          >
            {proposalTemplates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ pr: '10px', mb: "10px" }}>
        <TextField
          label="Contract ID"
          variant="outlined"
          fullWidth
          size="small"
          value={proposalDetails?.cmas_id ? proposalDetails.cmas_id : ''}
          onChange={(e) => dispatchState(updateSelectedProposalDetails({ 'cmas_id': e.target.value }))}
          InputLabelProps={{ shrink: proposalDetails?.cmas_id }}
          onBlur={(e) => updateProposal({ 'cmas_id': e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sx={{ pr: '10px', mb: "12px" }}>
        <TextField
          label="Included Area"
          variant="outlined"
          fullWidth
          size="small"
          value={proposalDetails?.included_area ? proposalDetails.included_area : ''}
          onChange={(e) => dispatchState(updateSelectedProposalDetails({ 'included_area': e.target.value }))}
          InputLabelProps={{ shrink: proposalDetails?.included_area }}
          onBlur={(e) => updateProposal({ 'included_area': e.target.value })}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ pr: '10px', display: "flex", justifyContent: "space-around" }}
      >
        <Button
          size="small"
          onClick={importMaterialItems}
          fullWidth
          sx={{ pt: "8px" }}
        >
          Import Materials
        </Button>
        <Button
          size="small"
          onClick={importLaborItems}
          fullWidth
          sx={{ pt: "8px" }}
        >
          Import Labor
        </Button>
        <Button
          size="small"
          onClick={importFreightItems}
          fullWidth
          sx={{ pt: "8px" }}
        >
          Import Freight
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ pr: '10px', display: "flex", justifyContent: "space-around" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={proposalDetails?.is_itemized}
              onChange={e => updateProposal({ 'is_itemized': e.target.checked, 'include_price_category_breakdown': e.target.checked ? proposalDetails.include_price_category_breakdown : false, 'include_sales_tax': e.target.checked ? proposalDetails.include_sales_tax : false })}
            />
          }
          label="Itemize"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={proposalDetails?.include_price_category_breakdown}
              onChange={e => updateProposal({ 'include_price_category_breakdown' : e.target.checked })}
              disabled={!proposalDetails?.is_itemized}
            />
          }
          label="MFL Breakdown"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={proposalDetails?.include_sales_tax}
              onChange={e => updateProposal({ 'include_sales_tax': e.target.checked })}
              disabled={!proposalDetails?.is_itemized}
            />
          }
          label="Sales Tax"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          pr: "10px",
          mt: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "1.2rem" }}>Inclusions</Typography>
        <Grid container justifyContent="flex-end">
          <Grid
            item
            sx={{
              marginRight: "4px",
              visibility: selectedInclusions.length ? "visible" : "hidden",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <IconButton
                aria-label="remove-inclusion"
                onClick={handleInclusionRowDelete}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <IconButton
              aria-label="add-inclusion"
              onClick={handleAddInclusionRow}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ pr: '10px', mb: "11px", width: "100%" }}>
        {proposalDetails?.inclusions?.length ? (
          <DataGridPro
            apiRef={inclusionsApiRef}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                display: proposalDetails?.is_itemized ? "block" : "none",
              },
            }}
            autoHeight
            hideFooter
            rows={proposalDetails?.inclusions}
            columns={proposalDetails?.is_itemized ? itemizedColumns : descriptionColumns}
            processRowUpdate={handleInclusionEditCommit}
            onProcessRowUpdateError={(error) => console.error(error)}
            experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            onRowSelectionModelChange={(newSelectionModel) =>
              handleInclusionRowSelectionModelChange(newSelectionModel)
            }
            rowSelectionModel={selectedInclusions}
            getRowId={(row) => row.id}
            onCellClick={(params, e) => e.stopPropagation()}
            localeText={{ noRowsLabel: "No Inclusions" }}
            onCellKeyDown={handleCellKeyDown}
            rowReordering
            onRowOrderChange={(e) => {
              let mutableInclusions = [...proposalDetails?.inclusions];
              const sourceRow = mutableInclusions[e.oldIndex];
              mutableInclusions.splice(e.oldIndex, 1);
              mutableInclusions.splice(e.targetIndex, 0, sourceRow);
              updateProposal({ 'inclusions': mutableInclusions });
            }}
          />
        ) : null}
      </Grid>
      {proposalDetails?.include_price_category_breakdown && (
        <>
          <Grid
            item
            xs={12}
            sm={3.25}
            md={3}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <Typography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Material Total: <strong>${materialTotal}</strong>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <Typography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Labor Total: <strong>${laborTotal}</strong>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3.5}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <Typography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Freight Total: <strong>${freightTotal}</strong>
            </Typography>
          </Grid>
        </>
      )}
      {proposalDetails?.include_sales_tax && (
        <>
          <Grid
            item
            xs={12}
            sm={3.25}
            md={3}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <Typography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Taxable Total: <strong>${taxableTotal.toFixed(2)}</strong>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <Typography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Added Sales Tax: <strong>${addedSalesTax.toFixed(2)}</strong>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3.5}
            sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
          >
            <Typography
              sx={{ fontSize: { xs: "11px", sm: "13px", md: "16px" } }}
            >
              Total: <strong>${totalAfterSalesTax.toFixed(2)}</strong>
            </Typography>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        sx={{
          pr: '10px',
          mt: proposalDetails?.include_price_category_breakdown || proposalDetails?.include_sales_tax ? "10px" : "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "1.2rem" }}>Exclusions</Typography>
        <Grid container justifyContent="flex-end">
          <Grid
            item
            sx={{
              marginRight: "4px",
              visibility: selectedExclusions.length ? "visible" : "hidden",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <IconButton
                aria-label="remove-exclusion-item"
                onClick={handleExclusionRowDelete}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <IconButton
              aria-label="add-exclusion-item"
              onClick={handleAddExclusionRow}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ pr: '10px', mb: "11px", width: "100%" }}>
        {proposalDetails?.exclusions?.length ? (
          <DataGridPro
            apiRef={exclusionsApiRef}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                display: "none",
              },
            }}
            autoHeight
            hideFooter
            rows={proposalDetails?.exclusions}
            columns={descriptionColumns}
            processRowUpdate={handleExclusionEditCommit}
            onProcessRowUpdateError={(error) => console.error(error)}
            experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            onRowSelectionModelChange={
              handleExclusionRowSelectionModelChange
            }
            rowSelectionModel={selectedExclusions}
            getRowId={(row) => row.id}
            onCellClick={(params, e) => e.stopPropagation()}
            onCellKeyDown={handleCellKeyDown}
            localeText={{ noRowsLabel: "No Exclusions" }}
            rowReordering
            onRowOrderChange={(e) => {
              let mutableExclusions = [...proposalDetails?.exclusions];
              const sourceRow = mutableExclusions[e.oldIndex];
              mutableExclusions.splice(e.oldIndex, 1);
              mutableExclusions.splice(e.targetIndex, 0, sourceRow);
              updateProposal({ 'exclusions': mutableExclusions });
            }}
          />
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          pr: '10px',
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "1.2rem" }}>Notes</Typography>
        <Grid container justifyContent="flex-end">
          <Grid
            item
            sx={{
              marginRight: "4px",
              visibility: selectedNotes.length ? "visible" : "hidden",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <IconButton
                aria-label="remove-description-item"
                onClick={handleNoteRowDelete}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <IconButton
              aria-label="add-description-item"
              onClick={handleAddNoteRow}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ pr: '10px', mb: "11px", width: "100%" }}>
        {proposalDetails?.notes?.length ? (
          <DataGridPro
            apiRef={notesApiRef}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                display: "none",
              },
            }}
            autoHeight
            hideFooter
            rows={proposalDetails?.notes}
            columns={descriptionColumns}
            processRowUpdate={handleNoteEditCommit}
            onProcessRowUpdateError={(error) => console.error(error)}
            experimentalFeatures={{ newEditingApi: true }}
            checkboxSelection
            onRowSelectionModelChange={handleNoteRowSelectionModelChange}
            rowSelectionModel={selectedNotes}
            getRowId={(row) => row.id}
            onCellClick={(params, e) => e.stopPropagation()}
            onCellKeyDown={handleCellKeyDown}
            rowReordering
            localeText={{ noRowsLabel: "No Notes" }}
            onRowOrderChange={(e) => {
              let mutableNotes = [...proposalDetails?.notes];
              const sourceRow = mutableNotes[e.oldIndex];
              mutableNotes.splice(e.oldIndex, 1);
              mutableNotes.splice(e.targetIndex, 0, sourceRow);
              updateProposal({ 'notes': mutableNotes });
            }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ProposalDetails;
