import React, { forwardRef, useRef, useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Tooltip,
  FormHelperText,
  Typography,
  Box,
  IconButton,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { decoratedFetch } from "../req_utils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Snackbar from "@mui/material/Snackbar";
import CreateProposalDialog from "./dialogs/CreateProposalDialog";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FilePreview from "./FilePreview";
import Slide from "@mui/material/Slide";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  refreshProposalLocations,
  refreshProposalCustomers,
  updateSelectedProposal,
  selectProposal,
  updateProposals,
  refreshFiles,
} from "../reducers";
import { GENESIS_LOGO_COLOR } from "../constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";

dayjs.extend(utc);

const SlowSlideTransition = forwardRef(
  function SlowSlideTransition(props, ref) {
    return <Slide ref={ref} {...props} timeout={{ enter: 600, exit: 0 }} />;
  },
);

const ProposalRibbon = () => {
  const dispatchState = useDispatch();
  const { project_id } = useParams();
  const project = useSelector(state => state.project);
  const selectedProposal = useSelector(state => state.selectedProposal);
  const proposals = useSelector(state => state.proposals);
  const refreshCustomers = useSelector(
    state => state.refreshProposalCustomers,
  );
  const refreshLocations = useSelector(
    (state) => state.refreshProposalLocations,
  );
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [createProposalDialogOpen, setCreateProposalDialogOpen] =
    useState(false);
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [selectedProposalPresent, setSelectedProposalPresent] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [previewFile, setPreviewFile] = useState("");
  const [previewFileLink, setPreviewFileLink] = useState("");
  const locationsSelectRef = useRef(null);

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputLabel: {
            styleOverrides: {
              root: {
                marginTop: selectedProposal?.start_date ? "0px" : "-7px",
              },
            },
          },
        },
      }),
    [selectedProposal],
  );

  const updateProposal = fieldsToUpdate => {
    decoratedFetch(`/update_proposal/${selectedProposal.id}`, {
      method: "PUT",
      body: JSON.stringify(fieldsToUpdate),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Error message generated");
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to save proposal");
        console.error(`Error updating proposal: ${error}`);
      });
  };

  useEffect(() => {
    setCreateButtonDisabled(!allRequiredProposalFieldsPopulated(selectedProposal));
    setSelectedProposalPresent(Object.keys(selectedProposal).length !== 0);
  }, [selectedProposal]);

  useEffect(() => {
    decoratedFetch(`/list_proposals/${project_id}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        dispatchState(updateProposals(data));
        dispatchState(selectProposal(data[0].id));
      })
      .catch((error) => {
        console.error(`Error listing proposals: ${error}`);
        dispatchState(updateProposals([]));
      });
    decoratedFetch(
      `/list_contacts?project_id=${project_id}&account_id=${project?.account?.id ?? ""}`,
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setCustomers(data))
      .catch((error) => {
        console.error(`Error fetching project contacts: ${error}`);
        setCustomers([]);
      });
    decoratedFetch(
      `/list_addresses?project_id=${project_id}&account_id=${project?.account?.id ?? ""}`,
    )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setLocations(data);
      })
      .catch((error) => {
        console.error(`Error fetching project addresses: ${error}`);
        setLocations([]);
      });
    decoratedFetch("/list_employees")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setEmployees(data.filter((employee) => employee.enabled)))
      .catch((error) => {
        console.error(`Error listing employees: ${error}`);
        setEmployees([]);
      });
  }, [project]);

  useEffect(() => {
    if (refreshCustomers) {
      decoratedFetch(
        `/list_contacts?project_id=${project_id}&account_id=${project?.account?.id ?? ""}`,
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Unknown error");
          }
          return response.json();
        })
        .then((data) => {
          setCustomers(data);
          dispatchState(refreshProposalCustomers(false));
        })
        .catch((error) => {
          console.error(`Error fetching project contacts: ${error}`);
          setCustomers([]);
        });
    }
  }, [refreshCustomers]);

  useEffect(() => {
    if (refreshLocations) {
      decoratedFetch(
        `/list_addresses?project_id=${project_id}&account_id=${project?.account?.id ?? ""}`,
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Unknown error");
          }
          return response.json();
        })
        .then((data) => {
          setLocations(data);
          dispatchState(refreshProposalLocations(false));
        })
        .catch((error) => {
          console.error(`Error fetching project addresses ${error}`);
          setLocations([]);
        });
    }
  }, [refreshLocations]);

  const handleCreateProposalDialogClose = (
    created = false,
    file = null,
    previewLink = "",
  ) => {
    setCreateProposalDialogOpen(false);
    if (created) {
      setSnackbarMessage("Created proposal and added to files");
      dispatchState(refreshFiles(true));
      if (file && previewLink) {
        setPreviewFile(file);
        setPreviewFileLink(previewLink);
      }
    }
  };

  const onCustomerChange = (customerId) => {
    if (customerId === selectedProposal?.customer?.id) {
      return;
    }
    const newCustomer = customers.find((e) => e.id === customerId);
    dispatchState(
      updateSelectedProposal({
        ...selectedProposal,
        customer: newCustomer,
      }),
    );
    updateProposal({ 'customer_id': customerId });
  };

  const onLocationChange = (locId) => {
    if (locId === selectedProposal?.location?.id) {
      return;
    }
    const newLoc = locations.find((loc) => loc.id === locId);
    dispatchState(
      updateSelectedProposal({
        ...selectedProposal,
        location: newLoc,
      }),
    );
    updateProposal({ 'location_id': locId });
  };

  const onStartDateChange = (startDate) => {
    dispatchState(
      updateSelectedProposal({
        ...selectedProposal,
        start_date: startDate,
      }),
    );
    updateProposal({ 'start_date': startDate });
  };

  const onSubmittedByChange = (submittedById) => {
    if (submittedById === selectedProposal?.submitted_by?.id) {
      return;
    }
    const newSubmittedBy = employees.find((e) => e.id === submittedById);
    dispatchState(
      updateSelectedProposal({
        ...selectedProposal,
        submitted_by: newSubmittedBy,
      }),
    );
    updateProposal({ 'submitted_by_employee_id': submittedById });
  };

  const allRequiredProposalFieldsPopulated = (proposal) => {
    return (
      proposal?.customer &&
      Object.keys(proposal?.customer).length !== 0 &&
      proposal?.location &&
      Object.keys(proposal?.location).length !== 0 &&
      proposal?.submitted_by &&
      Object.keys(proposal?.submitted_by).length !== 0
    );
  };

  const addressToStr = (addressObj) => {
    if (!addressObj || Object.keys(addressObj).length === 0) {
      return "";
    }
    const address = addressObj.address;
    return (
      <div>
        <span
          style={{ fontWeight: "bold" }}
        >{`${addressObj.project_address_type}`}</span>{" "}
        (
        {`${address.line_1}${address.line_2 ? ` ${address.line_2}` : ""}, ${address.city}, ${address.state} ${address.zip}`}
        )
      </div>
    );
  };

  const contactToStr = (contact) => {
    if (!contact || Object.keys(contact).length === 0) {
      return "";
    }
    return `${contact.first_name} ${contact.last_name}`;
  };

  const handlePreviewClose = () => {
    setPreviewFile(null);
    setPreviewFileLink("");
  };

  return (
    <>
      {!previewFileLink ? null : (
        <FilePreview
          transitionComponent={SlowSlideTransition}
          handleClose={handlePreviewClose}
          url={previewFileLink}
          file={previewFile}
        />
      )}
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
      />
      <CreateProposalDialog
        open={createProposalDialogOpen}
        isProposalDraft={selectedProposal.is_draft}
        handleClose={handleCreateProposalDialogClose}
        proposal={selectedProposal}
        customer={selectedProposal.customer}
        location={selectedProposal.location}
        startDate={selectedProposal.start_date}
        submittedBy={selectedProposal.submitted_by}
      />
      <Grid container spacing={2} paddingTop={1}>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth>
            <InputLabel size="small">Proposal Name</InputLabel>
            <Select
              value={selectedProposalPresent ? selectedProposal?.id : ""}
              onChange={(e) => dispatchState(selectProposal(e.target.value))}
              label="Proposal Name"
              size="small"
              fullWidth
              renderValue={(selectedId) => {
                const selected = proposals.find(
                  (proposal) => proposal.id === selectedId,
                );
                return selected ? (
                  selected.is_draft ? (
                    <Typography
                      sx={{ fontStyle: "italic", fontSize: "15.5px" }}
                    >
                      {selected.name}
                    </Typography>
                  ) : (
                    selected.name
                  )
                ) : (
                  ""
                );
              }}
              MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                    overflowY: "auto",
                  },
                },
              }}
            >
              {proposals.map((proposal) => (
                <MenuItem
                  key={proposal.id}
                  value={proposal.id}
                  sx={{
                    fontStyle:
                      proposal.id === "new_proposal" ? "italic" : "normal",
                  }}
                >
                  {proposal.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth>
            <InputLabel size="small">Customer</InputLabel>
            <Select
              id="customer"
              size="small"
              value={
                selectedProposalPresent
                  ? selectedProposal?.customer?.id
                    ? selectedProposal.customer.id
                    : ""
                  : ""
              }
              renderValue={(customerId) =>
                !customerId || !customers.length
                  ? ""
                  : contactToStr(customers.find((e) => e.id === customerId))
              }
              onChange={(e) => onCustomerChange(e.target.value)}
              label="Customer"
              fullWidth
              MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                    overflowY: "auto",
                  },
                },
              }}
            >
              {customers.length > 0 ? (
                customers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {contactToStr(option)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No contacts available</MenuItem>
              )}
            </Select>
            { selectedProposal.customer && Object.keys(selectedProposal?.customer).length > 0 ? null : <FormHelperText sx={{ mb: 0 }}>Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel size="small">Location</InputLabel>
            <Select
              id="location"
              size="small"
              value={
                selectedProposalPresent
                  ? selectedProposal?.location?.id
                    ? selectedProposal.location.id
                    : ""
                  : ""
              }
              renderValue={(locId) =>
                !locId || !locations.length ? (
                  ""
                ) : (
                  <Box
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    {addressToStr(locations.find((loc) => loc.id === locId))}
                  </Box>
                )
              }
              onChange={(e) => onLocationChange(e.target.value)}
              label="Location"
              fullWidth
              ref={locationsSelectRef}
              MenuProps={{
                getContentAnchorEl: null,
                disableScrollLock: true,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PaperProps: {
                  style: {
                    width: locationsSelectRef.current
                      ? locationsSelectRef.current.clientWidth
                      : "auto",
                    maxHeight: "300px",
                    overflowY: "auto",
                  },
                },
              }}
            >
              {locations.length > 0 ? (
                locations.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      {addressToStr(option)}
                    </Box>
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No addresses available</MenuItem>
              )}
            </Select>
            { selectedProposal.location && Object.keys(selectedProposal?.location).length > 0 ? null : <FormHelperText sx={{ mb: 0 }}>Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={4} lg={2} sx={{ pt: { xs: '12px !important', lg: '16px !important' }, pb: { xs: '8px', lg: 0 } }}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                onChange={onStartDateChange}
                value={
                  selectedProposalPresent
                    ? selectedProposal?.start_date
                      ? dayjs.utc(selectedProposal.start_date)
                      : null
                    : null
                }
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8.5px 0px 8.5px 14px",
                    width: "100%",
                  },
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
        </Grid>
        <Grid item xs={4} lg={2} sx={{ pt: { xs: '12px !important', lg: '16px !important' }, pb: { xs: '8px', lg: 0 } }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel size="small">Submitted By</InputLabel>
            <Select
              id="submitted-by"
              size="small"
              value={
                selectedProposalPresent
                  ? selectedProposal?.submitted_by?.id
                    ? selectedProposal.submitted_by.id
                    : ""
                  : ""
              }
              renderValue={(employeeId) =>
                !employeeId || !employees.length
                  ? ""
                  : contactToStr(employees.find((e) => e.id === employeeId))
              }
              onChange={(e) => onSubmittedByChange(e.target.value)}
              label="Submitted By"
              fullWidth
              MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                    overflowY: "auto",
                  },
                },
              }}
            >
              {employees.length > 0 ? (
                employees.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {contactToStr(option)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No employees available</MenuItem>
              )}
            </Select>
          </FormControl>
          { selectedProposal.submitted_by && Object.keys(selectedProposal?.submitted_by).length > 0 ? null : <FormHelperText sx={{ mb: 0, mx: "14px" }}>Required</FormHelperText>}
        </Grid>
        <Grid item xs={4} lg={2} sx={{ pt: { xs: '12px !important', lg: '16px !important' }, pb: { xs: '8px', lg: 0 } }}>
          <div style={{ display: "flex" }}>
            <span style={{ display: "inline-flex", width: "100%" }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setCreateProposalDialogOpen(true)}
                startIcon={<PostAddIcon />}
                disabled={createButtonDisabled}
              >
                {selectedProposal?.is_draft ? 'Create' : 'Create PDF'}
              </Button>
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ProposalRibbon;
